<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-5">
        Solid potassium chlorate,
        <chemical-latex content="KClO3," />
        can be decomposed by heating it in a test tube in an arrangement such as that shown in the
        image below. The balanced equation for the reaction is: The oxygen gas is collected in a
        container that is initially filled with water and inverted in a water pan.
      </p>

      <p class="mb-5 pl-8">
        <chemical-latex content="2 KClO3(s) -> 2 KCl(s) + 3 O2(g)" />
      </p>

      <p class="mb-2">
        The oxygen gas is collected in a container that is initially filled with water and inverted
        in a water pan.
      </p>

      <p class="mb-3 pl-8">
        <img src="/img/assignments/gasOverWater1.png" style="max-width: 50%; height: auto" />
      </p>

      <p class="mb-5">
        Suppose a sample of
        <chemical-latex content="KClO3" />
        is partially decomposed in this manner and the volume of gas collected is
        <number-value :value="volume" unit="\text{L}" />
        at
        <stemble-latex content="$26^\circ\text{C}$" />
        and
        <number-value :value="pressure" unit="\text{Torr}" />
        total pressure.
      </p>

      <p class="mb-2">
        a) What is the partial pressure of
        <chemical-latex content="O2(g)" />
        collected in the container? You may assume that the vapour pressure of water at
        <stemble-latex content="$26^\circ\text{C}$" />
        and
        <stemble-latex content="$25.0\,\text{Torr.}$" />
      </p>

      <calculation-input
        v-model="inputs.PO2"
        class="mb-4"
        prepend-text="$\text{P}_{\text{O}_2}:$"
        append-text="$\text{Torr}$"
        :disabled="!allowEditing"
      />

      <p class="mb-2">
        b) How many moles of
        <chemical-latex content="O2(g)" />
        were collected?
      </p>

      <calculation-input
        v-model="inputs.mol"
        prepend-text="$\ce{O2:}$"
        append-text="$\text{mol}$"
        class="mb-4"
        :disabled="!allowEditing"
      />

      <p class="mb-2">
        c) How many grams of
        <chemical-latex content="KClO3(s)" />
        (mw = 122.55 g/mol) were decomposed?
      </p>

      <calculation-input
        v-model="inputs.mass"
        prepend-text="$\text{Mass:}$"
        append-text="$\text{g}$"
        class="mb-4"
        :disabled="!allowEditing"
      />

      <p class="mb-2">
        d) When dry, what volume would the collected
        <chemical-latex content="O2" />
        occupy at the same temperature and pressure?
      </p>

      <calculation-input
        v-model="inputs.vol"
        prepend-text="$\text{Volume:}$"
        append-text="$\text{L}$"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import CalculationInput from '../inputs/CalculationInput';
import NumberValue from '../NumberValue';
import ChemicalLatex from '@/tasks/components/displayers/ChemicalLatex';
import StembleLatex from '@/tasks/components/StembleLatex';

export default {
  name: 'Question395',
  components: {
    CalculationInput,
    NumberValue,
    ChemicalLatex,
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        PO2: null,
        mol: null,
        mass: null,
        vol: null,
      },
    };
  },
  computed: {
    pressure() {
      return this.taskState.getValueBySymbol('pressure').content;
    },
    volume() {
      return this.taskState.getValueBySymbol('volume').content;
    },
  },
};
</script>
